<template>
  <div class="container-fluid bg-gris-claro" id="faq">
    <div class="container">
      <div class="row pt-4">
        <h5>{{ $t("faq.titulo") }}</h5>
      </div>
      <div class="row pb-5">
        <section>
          <h4>{{ $t("faq.tecnicas.titulo") }}</h4>
          <div v-for="item in tecnicas" :key="tecnicas.indexOf(item)" class="pack">
            <h6>{{ item.pregunta}}</h6>
            <p class="rta" v-html="item.rta"></p>
          </div>
        </section>

        <section>
          <h4>{{ $t("faq.comerciales.titulo") }}</h4>
          <div v-for="item in comerciales" :key="comerciales.indexOf(item)" class="pack">
            <h6>{{ item.pregunta}}</h6>
            <p class="rta" v-html="item.rta"></p>
          </div>
        </section>

        <section>
          <h4>{{ $t("faq.servicio.titulo") }}</h4>
          <div v-for="item in servicio" :key="servicio.indexOf(item)" class="pack">
            <h6>{{ item.pregunta}}</h6>
            <p class="rta" v-html="item.rta"></p>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import es from "@/locales/es.json";
import en from "@/locales/en.json";

export default {
  name: "Faq",
  // },
  computed: {
    tecnicas() {
      return this.getTecnicas()
    },
    comerciales() {
      return this.getcComerciales()
    },
    servicio() {
      return this.getServicio()
    }
  },
  methods: {
    getTecnicas() {
      return (this.$i18n.locale === "en") ? en.faq.tecnicas.preguntas :  es.faq.tecnicas.preguntas;
    },
    getcComerciales() {
      return (this.$i18n.locale === "en") ? en.faq.comerciales.preguntas : es.faq.comerciales.preguntas;
    },
    getServicio() {
      return  (this.$i18n.locale === "en") ? en.faq.servicio.preguntas : es.faq.servicio.preguntas;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>